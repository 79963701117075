import React from 'react'

const Send = () => {
    return (
        <div className="send-container">
            <h5>Otrzymaliśmy wiadomość i wkrótce skontaktujemy się z Państwem.</h5>
        </div>
    )
}

export default Send
