import React from 'react'

const Footer = () => {
    return (
        <footer>
            <p className="footer-text">&copy; 2020 Carbonzero All rights reserved Created by Michał Pietrzak</p>
        </footer>
    )
}

export default Footer;
